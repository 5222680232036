import profile from './assets/profile.png';

export const personalData = {
    name: 'Usama Haider',
    designation: ['Full Stack Developer', 'Backend Developer'],
    github: 'https://github.com/UsamaHaide0786',
    linkedIn: 'https://www.linkedin.com/in/usama-haider-1697aa1a1/',
    facebook: 'https://www.facebook.com/share/i7KbTQNCRR3cwavd/?mibextid=LQQJ4d',
    instagram: 'https://www.instagram.com/usama.haider0786?igsh=MWdyazh2Z2JyY2x6cQ==',
    skills: ['Python', 'Django', 'Django Rest Framework', 'Flask', 'RestAPI', 'Elixir', 'Phoenix', 'PostgreSQL', 'MySQL', 'ReactJs', 'Bootstrap', 'Docker', 'AWS', 'Node.js', 'React Native','PHP','Laravel','Data Science','SAAS','FastAPI','MongoDB', 'html', 'css', 'javascript', 'git','redis', 'graphql', 'microservices', 'jenkins', 'gcp'],
    email: "usamahaiderr123@gmail.com",
    phone: '+447440010878',
    address: 'Cheltenham, United Kingdom'
};

export const AboutData = {
    name: 'Usama Haider',
    description: `I am an experienced software engineer with over 5 years of expertise in web/APIs, mobile apps, and custom software development using technologies like Elixir, Python, Django, React, and more. I've worked across various industries, including Gaming, Real Estate, and E-commerce, and have led teams and managed complex projects for multinational companies. With a strong foundation in software engineering and a passion for innovative solutions, I excel in producing robust code and enhancing organizational efficiency.`,
    profile: profile,
};

export const experiences = [
    {
        id: 1,
        title: 'Senior Software Engineer',
        company: 'Founders Network',
        duration: '(Dec 2022 - Present)',
        location: 'New York, United States',
        responsibilities: [
            'Backend development with Django and Django Rest Framework',
            'Frontend development with ReactJs and Bootstrap',
            'Managing large databases',
            'Project design and structure',
            'Server-side security',
            'Microservices implementation',
            'Performance and optimization of live products'
        ]
    },
    {
        id: 2,
        title: 'Senior Software Engineer',
        company: 'Freelance',
        duration: '(Aug 2021 - Present)',
        location: 'Gloucestershire, United Kingdom',
        responsibilities: [
            'Senior backend development with Django, Flask, FastAPI, Elixir/Phoenix',
            'Mid-level front development with ReactJs',
            'DevOps with Heroku, AWS, Fly.io, Docker',
            'Managing high-end large scale databases',
            'Project design and structure',
            'Server-side security',
            'Microservices implementation',
            'Performance and optimization of live products'
        ]
    },
    {
        id: 3,
        title: 'Senior Software Engineer',
        company: 'Ecosystem Marketplace',
        duration: '(Aug 2022 - Nov 2022)',
        location: 'Cheltenham, United Kingdom',
        responsibilities: [
            'Backend development with Django and Django Rest Framework',
            'Frontend development with ReactJs and Bootstrap',
            'Managing large databases',
            'Project design and structure',
            'Server-side security',
            'Microservices implementation',
            'Performance and optimization of live products'
        ]
    },
    {
        id: 4,
        title: 'Software Engineer',
        company: 'InvoZone',
        duration: '(Apr 2021 - Jan 2022)',
        location: 'Lahore, Pakistan',
        responsibilities: [
            'Backend development with Django, Flask, FastAPI, Elixir/Phoenix',
            'Frontend development with ReactJs and Bootstrap',
            'Managing large databases',
            'Project design and structure',
            'Server-side security',
            'Microservices implementation',
            'Performance and optimization of live products'
        ]
    },
    {
        id: 5,
        title: 'Django Backend Developer',
        company: 'Generate Intel',
        duration: '(Sep 2020 - May 2021)',
        location: 'Islamabad, Pakistan',
        responsibilities: [
            'Backend development with Django',
            'Microservices implementation',
            'RestAPIs development using Django Rest Framework'
        ]
    },
    {
        id: 6,
        title: 'Python Developer',
        company: 'Intellisence',
        duration: '(Dec 2019 - Mar 2021)',
        location: 'Lahore, Pakistan',
        responsibilities: [
            'Backend development with Django, Flask, FastAPI',
            'Python scripting and data parsing',
            'Managing large databases',
            'Project design and structure',
            'Server-side security',
            'Microservices implementation',
            'Performance and optimization of live products'
        ]
    },
    {
        id: 7,
        title: 'Python Developer',
        company: 'Asemteq',
        duration: '(May 2018 - Sep 2018)',
        location: 'Lahore, Pakistan',
        responsibilities: [
            'Python desktop application development using Tkinter',
            'Python scripting and data parsing'
        ]
    }
];

export const skillsData = ['Python', 'Django', 'Django Rest Framework', 'Flask', 'RestAPI', 'Elixir', 'Phoenix', 'PostgreSQL', 'MySQL', 'ReactJs', 'Bootstrap', 'Docker', 'AWS', 'NodeJs', 'React Native','PHP','Laravel','DataScience','SAAS','FastAPI','MongoDB', 'html', 'css', 'javascript', 'git','redis', 'graphql', 'microservices', 'jenkins', 'gcp'];

export const projectsData = [
    {
        id: 1,
        name: 'FoundersNetwork',
        description: `Founders Network is a curated community of over 650 full-time tech startup founders from around the world. Our founders-only forum, roundtable events, and other high-touch programs facilitate authentic.`,
        liveUrl: `https://foundersnetwork.com/`,
        role: 'Full Stack Developer',
    },
    {
        id: 2,
        name: 'Taskeen',
        description: `Taskeen Al Oula Real Estate Co., established in 2018, offers technology-driven, comprehensive property management services. Our digital platform includes online rent collection, 24-hour maintenance, asset management, and detailed reporting. We aim to maximize profits and provide professional, convenient property management accessible anytime, anywhere.`,
        liveUrl: `https://taskeen.co/`,
        role: 'Full Stack Developer',
    },
    {
        id: 3,
        name: 'Ecosystem Marketplace',
        description: `Ecosystem Marketplace, an initiative of the non-profit organization Forest Trends, is a leading global source of information on environmental finance, markets, and payments for ecosystem services.`,
        liveUrl: `https://www.ecosystemmarketplace.com/`,
        role: 'Full Stack Developer',
    },
    {
        id: 4,
        name: 'Empoweringconfidentyouth',
        description: `Teaching Students to Grow into Confident and Successful Adults. An innovative social-emotional learning program flexible to fit any classroom in multiple languages. Using engaging learning modules with diverse characters, and personalized growth tools meant to enhance your teaching methods and their learning styles.`,
        liveUrl: `https://www.empoweringconfidentyouth.app/login`,
        role: 'Full Stack Developer',
    },
    {
        id: 5,
        name: 'Metaland',
        description: 'Metaland project will create all the real communication and services in the virtual world, and by joining our world, clients will be able to get all the services in one place easily.',
        liveUrl: `https://metaland.mn/en/about`,
        role: 'Full Stack Developer',
    },
    {
        id: 6,
        name: 'Classed',
        description: 'Classeed has developed true garlic seeds made from garlic flowers. The seeds enable making new varieties and genotypes with completely new plant DNA. Those varieties can be planted in various areas worldwide and open the door to revolution in garlic production.',
        liveUrl: `https://classeed.com/`,
        role: 'Full Stack Developer',
    },
    {
        id: 7,
        name: 'Knab',
        description: 'Knab is the bank for the self-employed. Via the fine Knab App - or our website - you can arrange all your entrepreneurial affairs in an instant. As an online bank, we attach extra importance to human service. Between 08.00 and 20.00 there is someone ready to help you every day.',
        liveUrl: `https://www.knab.nl/`,
        role: 'Full Stack Developer',
    }
];

export const educations = [
    {
        id: 1,
        title: 'MSc Data Science',
        duration: '2022 - 2023',
        institution: 'University of Gloucestershire',
    },
    {
        id: 2,
        title: 'Bachelors in Computer Science',
        duration: '2017 - 2020',
        institution: 'University of Management and Technology, Lahore',
    }
];

export const ContactMe = {
    description: 'If you have any questions or concerns, please don\'t hesitate to contact me. I am open to any work opportunities that align with my skills and interests.',
};
 